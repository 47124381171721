import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Icons from "../components/Icons"

export default ({ data }) => {
  const post = data.markdownRemark
  console.log("post", post)
  const { frontmatter } = post
  const { icons } = frontmatter
  const iconNames = icons.split(",")

  return (
    post && (
      <Layout>
        <SEO title={post.frontmatter.title} />
        <div className="jumbo-title jumbo-mini p-4 container-fluid text-center bg-black">
          <div className="container">
            <div className="display-4 font-400 text-white">
              {post.frontmatter.title}
            </div>
            <div className="row my-2">
              <div className="col-md-6 offset-md-3 text-center">
                <p className="lead font-200 text-white">
                  by <i>{post.frontmatter.author}</i> - {post.frontmatter.date}
                </p>
                <div style={{height:100}}>
                  <Icons names={iconNames} fixedWidth={50}></Icons>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-content container p-3 my-3">
          <div
            className="font-300"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
      </Layout>
    )
  )
}

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        author
        date(formatString: "DD MMM YYYY")
        icons
      }
    }
  }
`
